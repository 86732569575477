// InsidePage.js
import React from "react";
import { useParams } from "react-router-dom";
import ModularFurnitureinsidePageData from "./ModularFurnitureinsidePageData";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ModularFurnitureInsidePage = () => {
  const navigate = useNavigate(); // Getting the navigate function

  const goBack = () => {
    navigate(-1); // This will go back one step in the history
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const { category, name } = useParams();
  const categoryData = ModularFurnitureinsidePageData[category];

  // console.log("hdvfubidggh" , categoryData)

  if (!categoryData) {
    return <p>Category not found</p>;
  }

  const pageData = categoryData.find((item) => item.name === name);

  

  if (!pageData) {
    return <p>Item not found</p>;
  }

  return (
    <div>
      <div className="inner-banner-2">
        {pageData.banner && (
          <img
            src={pageData.banner}
            alt={`${pageData.heading} Banner`}
            className="banner-image"
          />
        )}
        <h2>{pageData.cat}</h2>
      </div>
      <div className="Product-content-wrap">
        <div className="wrapper">
          {/* <div className="Product-content-1 ">
            <h1>{pageData.heading}</h1>
            <p>{pageData.description}</p>
          </div>
          <div className="gallery">
            {pageData.images.map((image, index) => (
              <img key={index} src={image.src} alt={image.alt} />
            ))}
          </div> */}
          <h1 className="text-center line-3">{pageData.heading}</h1>
          <div className="text-left">
            <div>
              <h3>{pageData.subheading}</h3>
              <p>{pageData.shortdis}</p>
              {pageData.description.split("\n\n").map((paragraph, index) => {
                if (paragraph.match(/^\d\.\s/)) {
                  // Bold the numbered headings
                  const [mainPoint, ...subpoints] = paragraph.split("\n");
                  return (
                    <div key={index}>
                      <p>
                        <b>{mainPoint.split(" - ")[0]}</b> {/* - */}{" "}
                        {mainPoint.split(" - ")[1]}
                      </p>
                      {subpoints.length > 0 && (
                        <ul>
                          {subpoints.map((subpoint, subIndex) => (
                            <li key={subIndex}>
                              <strong>{subpoint.split(":")[0]}:</strong>{" "}
                              {subpoint.split(":")[1]}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  );
                }
                return <p key={index}>{paragraph}</p>;
              })}
            </div>
          </div>
          <div className="gallery">
            {pageData.images.map((image, index) => (
              <img key={index} src={image.src} alt={image.alt} />
            ))}
          </div>
          <div className="backbtn" onClick={scrollToTop}>
            <Link onClick={goBack} className="button-3">
              Back
            </Link>
            &nbsp;&nbsp;
            <Link
              to="/modularfurniture#materialsandfinishes"
              className="button-3"
            >
              Materials and Finishes
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModularFurnitureInsidePage;
