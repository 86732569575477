// insidePageData.js

const ModularFurnitureinsidePageData = {
  productcategories: [
    // {
    //   id: 1,
    //   cat: "Modular Furniture",
    //   heading: "Discover Your Rostre Kitchen",
    //   description:
    //     "At Rostre Spaces, we believe in creating kitchens that reflect your unique style and meet your functional needs. With our modular kitchen solutions, you can design a space that truly feels like home. Here’s how you can bring your vision to life:",
    //   banner: "/images/Modular-F-kitchen.png",
    //   images: Array.from({ length: 9 }, (_, i) => ({
    //     src: `/images/kitchen/img-${i + 1}.png`,
    //     alt: `Product Category 1 - Image ${i + 1}`,
    //   })),
    // },
    {
      id: 1,
      cat: "Modular Furniture",
      heading: "Discover Your Rostre Kitchen",
      name: "kitchens",
      subheading: "Customize Your Kitchen Experience",
      shortdis:
        "Rostre Spaces offers bespoke modular kitchens and cabinets that seamlessly blend aesthetics with maximum functionality. Our designs are tailored to bring your vision to life, allowing you to choose from an extensive palette of colors and finishes. Complement your selections with our premium granite and quartz countertops for a cohesive and elegant look. Here’s how you can bring your vision to life:",
      description: `1. Set Your Budget - Choose materials that fit your financial plan by selecting from our premium, medium, or economy ranges. This flexibility ensures you get the best value without compromising on quality.

2. Select Your Layout - Depending on your available space, choose from various configurations:
• U-Shaped Kitchen: Ideal for maximizing storage and workspace.
• L-Shaped Kitchen: Perfect for open-concept designs, offering a cozy yet functional layout.
• Parallel Counter Kitchen: Great for efficient workflows, especially in smaller areas.

3. Choose Colors and Finishes - Personalize your kitchen by selecting the color and finish of your shutters. Our extensive palette includes options like:
• Laminates: Durable and available in a variety of patterns and textures.
• Acrylic: Offers a sleek, high-gloss finish that is easy to maintain.
• Veneer: Provides a natural wood look while maintaining the benefits of modular design.
• Lacquer: Known for its resistance to stains and easy upkeep.
• Membrane: Perfect designs and edge banded finishes.`,

      banner: "/images/Modular-F-kitchen.png",
      images: Array.from({ length: 15 }, (_, i) => ({
        src: `/images/kitchen/img-${i + 1}.png`, // Correct path from the public folder
        alt: `Product Category 1 - Image ${i + 1}`,
      })),
    },

    {
      id: 2,
      cat: "Modular Furniture",
      heading: "Wardrobes",
      name: "wardrobes",
      subheading: "Transform Your Space with Custom Solutions",
      shortdis:
        "At Rostre Spaces, our modular wardrobes are designed to maximize your storage while enhancing the aesthetic appeal of your bedroom. With a focus on functionality and style, our wardrobes offer a perfect blend of organization and elegance.",

      description: `1. Space Optimization - Our modular designs allow you to make the most of every inch in your room. Whether you have a compact space or a larger area, our wardrobes can be tailored to fit seamlessly, providing ample storage without overwhelming your environment.

2. Customizable Designs - Choose from a variety of styles, colors, and finishes to create a wardrobe that reflects your personal taste. Our modular wardrobes can be customized in terms of size, layout, and internal configurations, including shelves, drawers, and hanging rails.

3. Functional Layouts - Select from different wardrobe configurations such as:
• Sliding Door Wardrobes: Ideal for smaller spaces where hinged doors might be impractical.
• Openable Shutter Wardrobes: Offering full access with traditional swing doors.
• Walk-In Closets: For those who desire a dedicated space for their clothing and accessories.

4. Quality Materials - We use high-quality materials sourced from leading suppliers to ensure durability and longevity. Our wardrobes feature sturdy construction with options for premium finishes that add a touch of luxury to your home.

5. Smart Storage Solutions - Our modular wardrobes include innovative storage and space-saving solutions which help keep your stuff organized. These include:

• Adjustable shelves\n
• Pull-out drawers\n
• Shoe racks\n
• Accessories organizers\n
6. Elegant Aesthetics - With sleek designs and modern finishes, our wardrobes not only serve as functional storage but also enhance the overall look of your bedroom. Choose from various textures and colors to complement your existing decor.`,
      banner: "/images/Wardrobes-banner.png",
      images: Array.from({ length: 8 }, (_, i) => ({
        src: `/images/ProductCategories/Wardrobes/img-${i + 1}.png`, // Correct path from the public folder
        alt: `Product Category 1 - Image ${i + 1}`,
      })),
    },
    {
      id: 3,
      cat: "Modular Furniture",
      heading: "Bathroom Vanities",
      name: "bathroomvanities",
      subheading: "Elevate Your Bathroom with Stylish Vanities",
      shortdis:
        "At Rostre Spaces, we understand that bathroom vanities are essential elements that enhance both the functionality and aesthetics of your space. Our collection of bathroom vanities combines innovative design with high-quality materials, allowing you to create a stylish and organized sanctuary in your home.",

      description: `1. Variety of Styles - Choose from an extensive range of styles to suit your personal taste and bathroom decor:
• Single Sink Vanities: Ideal for smaller bathrooms or powder rooms, these compact units maximize storage while maintaining a sleek profile.
• Double Sink Vanities: Perfect for larger spaces or shared bathrooms, offering ample counter space and storage for convenience.
• Freestanding Vanities: These versatile units can be repositioned as needed and come in a variety of designs, from vintage-inspired to modern minimalist.
• Wall-Mounted Vanities: Create a contemporary look with floating designs that enhance the sense of space in your bathroom.

2. Tops - Finished with high-end tops in granite and quartz.

3. Innovative Features - Enjoy modern conveniences such as:

• Integrated LED lighting for enhanced visibility \n
• Smart storage solutions with drawers and shelves designed to keep your essentials organized.\n

• Vessel sinks that add a touch of elegance and sophistication.\n


4. Functional Design - Each vanity is designed with practicality in mind. Features like soft-closing drawers and easy-to-clean surfaces ensure that your vanity remains functional and stylish over time.

`,
      banner: "/images/Bathroom-vanities-banner.png",
      images: Array.from({ length: 4 }, (_, i) => ({
        src: `/images/ProductCategories/BathroomVanities/img-${i + 1}.png`, // Correct path from the public folder
        alt: `Product Category 1 - Image ${i + 1}`,
      })),
    },
    {
      id: 4,
      heading: "Consoles",
      name: "consoles",
      subheading: "",
      shortdis: "",
      cat: "Modular Furniture",
      description:
        "Our consoles combine modern design with practical functionality, offering stylish storage solutions for any space. Perfect for entryways, living rooms, or as media units, they enhance your decor while keeping essentials organized",
      banner: "/images/tv-consoles-banner.png",
      images: Array.from({ length: 7 }, (_, i) => ({
        src: `/images/ProductCategories/TVconsoles/img-${i + 1}.png`, // Correct path from the public folder
        alt: `Product Category 1 - Image ${i + 1}`,
      })),
    },
    {
      id: 5,
      cat: "Modular Furniture",
      heading: "Storage units ",
      name: "storageunits",
      subheading: "Maximize Your Space with Rostre Storage Solutions",
      shortdis:
        "At Rostre Spaces, we understand that effective storage is essential for maintaining an organized and functional living environment. Our modular storage units are designed to meet diverse needs, providing stylish and practical solutions for any space.",

      description: `1. Customizable Designs - Our storage units can be tailored to fit your specific requirements. Choose from various sizes, configurations, and styles to create a solution that perfectly complements your home or office.

2. Versatile Applications - Whether you need storage for household items, office supplies, or personal belongings, our units are versatile enough to accommodate any purpose. Ideal for:
• Living Rooms: Stylish cabinets and shelves to keep your space tidy.
• Bedrooms: Wardrobes and drawers designed for optimal organization.
• Home Offices: Functional units that enhance productivity while keeping essentials within reach.

3. Quality Materials - We prioritize durability and aesthetics in our storage solutions. Our units are crafted from high-quality materials sourced from leading suppliers, ensuring longevity and a polished look.

4. Smart Storage Solutions - Enjoy innovative features such as:

• Adjustable shelves for customizable space management.

5. Space-Saving Designs - Our modular storage units are designed to maximize space efficiency. With options like wall-mounted shelves and corner units, you can utilize every inch of your area without sacrificing style.

6. Aesthetic Appeal - Available in a wide range of colors, finishes, and styles, our storage units can seamlessly blend into any decor theme—from modern minimalism to classic elegance.`,
      banner: "/images/storagebannernew.png",
      images: Array.from({ length: 7 }, (_, i) => ({
        src: `/images/ProductCategories/StorageUnits/img-${i + 1}.png`, // Correct path from the public folder
        alt: `Product Category 1 - Image ${i + 1}`,
      })),
    },

    {
      id: 6,
      cat: "Modular Furniture",
      heading: "Office Furniture ",
      name: "officefurniture",
      subheading: "",
      //shortdis: "We can make modular furniture for your working spaces such as",

      //       description: `• Reception Tables \n
      // • Office desks \n
      // • Storage and filing units \n
      // • Cabinets \n
      // `,

      description: `We provide a wide range of office furniture, including office tables, reception desks, storage cabinets, bathroom vanities, and consoles, designed to enhance productivity and style in any workspace. Our customizable options ensure that each piece meets your specific needs while reflecting your brand's identity.`,

      banner: "/images/Storage-units-banner-2.png",
      images: Array.from({ length: 6 }, (_, i) => ({
        src: `/images/ProductCategories/office/img-${i + 1}.jpg`, // Correct path from the public folder
        alt: `Product Category 1 - Image ${i + 1}`,
      })),
    },
    // Add more items as needed
  ],
  materialoptions: [
    {
      id: 1,
      name: "laminates",
      heading: "Laminates",
      cat: "Shutter Finishes",
      banner: "/images/Laminates-banner.png",
      description: `At Rostre Spaces, we offer an extensive range of laminates to suit every style and budget. Our collection includes basic, premium, and luxury laminates, all manufactured by top brands known for their quality and durability. Whether you’re looking for a classic wooden look or something more modern, we have the perfect laminate to enhance your space.
      
      Our laminates are applied to plywood shutters, but can be put on MDF or HDHMR too, providing both aesthetic appeal and structural integrity. 

Below are just a few examples of the stunning laminates we offer: 



1. Wooden Looks - Classic finishes that bring warmth and character to any space. 

2. Stone Finishes - Elegant designs that mimic natural stone for a luxurious feel. 

3. Leather and Glass Effects - Unique textures that provide a contemporary edge.\n

While these are just a glimpse into our laminate offerings, we have an even wider selection available to meet your specific needs and preferences.
`,

      images: Array.from({ length: 18 }, (_, i) => ({
        src: `/images/MaterialOptions/Laminates/img-${i + 1}.png`, // Use actual image paths
        alt: `Material Option 1 - Image ${i + 1}`,
      })),
    },
    {
      id: 2,
      name: "paintandpu",
      heading: "Paint / PU",
      cat: "Shutter Finishes",
      banner: "/images/pu-banner.png",
      description: `At Rostre Spaces, we offer the latest in trendy painted shutters, featuring stylish grooves and lines that add depth and character to your cabinetry. Our painted shutters are crafted from high-quality MDF or HDHMR, ensuring a perfect finish that enhances the overall aesthetic of your space.
      1. Customizable Designs - Our storage units can be tailored to fit your specific requirements. Choose from various sizes, configurations, and styles to create a solution that perfectly complements your home or office. 



1. Premium Quality Materials - Our shutters are made from MDF (Medium Density Fiberboard) or HDHMR (High-Density High Moisture Resistant board), providing durability and moisture resistance, making them ideal for various environments, including kitchens and bathrooms. 



2. Collaboration with Top Paint Brands - We have partnered with renowned paint brands such as William Sherman, Asian Paints, and Berger to offer a wide range of finishes. Choose from matte, glossy, and various textured looks to perfectly match your interior design. 



3. Multi-Coat Painting Process - Each shutter undergoes a meticulous multi-coat painting process that ensures a flawless finish. The perfectly matched joints enhance the visual appeal and durability of the shutters, making them stand out in any room. 



4. Design Versatility - Our painted shutters come in a variety of trendy designs, including modern grooves and lines that can be customized to fit your style preferences. Whether you prefer a sleek contemporary look or a more traditional aesthetic, we have options to suit every taste. 



5. Easy Maintenance - The high-quality paint finishes are designed for easy cleaning and maintenance, ensuring that your shutters remain beautiful and vibrant over time.


`,
      images: Array.from({ length: 8 }, (_, i) => ({
        src: `/images/MaterialOptions/PU/img-${i + 1}.png`, // Use actual image paths
        alt: `Material Option 1 - Image ${i + 1}`,
      })),
    },
    {
      id: 3,
      name: "acrylic",
      heading: "Acrylic",
      cat: "Shutter Finishes",
      banner: "/images/Acrylic-banner.png",
      description: `At Rostre Spaces, our acrylic shutters offer a modern and stylish solution for your cabinetry needs. Known for their sleek appearance and high-gloss finish, these shutters are crafted from durable acrylic material, making them an excellent choice for both aesthetic appeal and functionality. The Key Features are: 



1. High-Gloss Finish - Acrylic shutters are available in a variety of vibrant colors with a stunning high-gloss finish that reflects light beautifully. This glossy surface adds a touch of luxury to any space, making them ideal for contemporary interiors.

2.  Durability and Strength  - Made from impact-resistant acrylic, these shutters are designed to withstand everyday wear and tear. They are highly resistant to scratches and moisture, making them suitable for high-humidity areas such as kitchens and bathrooms.

3.  Versatile Applications  - Our acrylic shutters can be mounted on various substrates, including plywood, MDF (Medium Density Fiberboard), or HDHMR (High-Density High Moisture Resistant board). This versatility allows you to choose the best material for your specific needs.

4. Easy Maintenance - The smooth surface of acrylic makes cleaning a breeze. Simply wipe down with a damp cloth to keep your shutters looking pristine. Their resistance to stains ensures that they maintain their vibrant appearance over time.`,
      images: Array.from({ length: 8 }, (_, i) => ({
        src: `/images/MaterialOptions/Acrylic/img-${i + 1}.png`, // Use actual image paths
        alt: `Material Option - Acrylic - Image ${i + 1}`,
      })),
    },

    {
      id: 4,
      heading: "Glass",
      name: "glass",
      cat: "Shutter Finishes",
      banner: "/images/Glass-banner.png",
      description: `At Rostre Spaces, we offer a stunning selection of lacquered glass and toughened glass shutters, complemented by sleek aluminum profiles. These contemporary design elements not only enhance the aesthetic appeal of your cabinetry but also provide durability and functionality. The Key Features are:



1. Glass - Our glass options come in a variety of vibrant colors, allowing you to customize your space with a high-gloss finish that reflects light beautifully. This finish is perfect for creating a modern and sophisticated look in any room. 

2. Toughened Glass - For added strength and safety, our toughened glass shutters are designed to withstand everyday use. This glass is heat-treated to increase its durability, making it an excellent choice for high-traffic areas.

3.  Aluminum Profiles - The integration of aluminum profiles enhances the structural integrity of the glass shutters while providing a sleek, modern frame. These profiles are available in various finishes to match your design preferences. 

4.  Versatile Applications - Our glass shutters can be mounted on various substrates, including plywood, MDF (Medium Density Fiberboard), or HDHMR (High-Density High Moisture Resistant board). This versatility allows you to choose the best material for your specific needs and aesthetic goals.

5. Easy Maintenance - Both lacquered and toughened glass surfaces are easy to clean and maintain, ensuring that your shutters remain pristine and vibrant over time. \n
    `,
      images: Array.from({ length: 10 }, (_, i) => ({
        src: `/images/MaterialOptions/Glass/img-${i + 1}.png`, // Use actual image paths
        alt: `Material Option - Glass - Image ${i + 1}`,
      })),
    },

    {
      id: 5,
      name: "thermofoilandmembrane",
      heading: "Thermofoil / Membrane",
      cat: "Shutter Finishes",
      banner: "/images/Thermofoil-banner.png",
      description: `Membrane / Thermofoil - 
Membrane or thermofoil shutters are a modern solution for cabinetry, offering a sleek, frameless design that enhances the aesthetic appeal of any space. These shutters are crafted as single-piece units with soft edges, providing a seamless look that is both stylish and functional. The Key Features are:



1. High-Quality Finish - Our membrane shutters feature a high gloss or matte print foil that is pressed onto MDF or HDHMR using advanced German membrane pressing technology. This process ensures a perfectly finished surface that is both visually appealing and durable.  

2. Material Composition - Made from high-density materials like MDF (Medium Density Fiberboard) or HDHMR (High-Density High Moisture Resistant board), these shutters provide excellent stability and moisture resistance, making them ideal for areas prone to humidity, such as kitchens and bathrooms. 

3. Design Versatility - Available in a wide range of colors and finishes, membrane shutters can be customized to match any interior design style. Whether you prefer a classic look or a contemporary finish, our selection allows for creative expression in your cabinetry.  

4. Soft Edges - The soft edges of membrane shutters not only enhance their visual appeal but also contribute to safety, reducing the risk of injury in high-traffic areas. 

5. Easy Maintenance - Membrane finishes are easy to clean and maintain, making them suitable for everyday use in busy households. Their smooth surfaces resist stains and can be wiped down effortlessly.`,
      images: Array.from({ length: 5 }, (_, i) => ({
        src: `/images/MaterialOptions/Thermfoil/img-${i + 1}.png`, // Use actual image paths
        alt: `Material Option 1 - Image ${i + 1}`,
      })),
    },

    // Add more items as needed
  ],
};

export default ModularFurnitureinsidePageData;
