import React from "react";
import { Link, useParams } from "react-router-dom";
import banner from "./images/Blog-banner.png";
const blogs = [
  {
    id: 1,
    title: "The Future of Home Design: Why Modular Interiors Lead the Way",
    subtitle: "Lorem Ipsum",
    image: "./images/blogthumb1.png",
    bannerImage: "../images/blogbanner-new-1.png",
    description: [
      {
        type: "p",
        content:
          "The way we design and experience our home interiors is evolving rapidly. Gone are the days when a single design template was expected to fit all homes. Today’s homeowners demand more—more personalization, more efficiency, and more durability. Enter the modular interior revolution, a game-changer in home design that perfectly aligns with the needs of modern living.",
      },
      {
        type: "p",
        content: (
          <>
            At <strong>Rostre Spaces</strong>, we are at the forefront of this
            transformation, not just keeping pace with trends but setting
            benchmarks. Here’s an in-depth look at why modular interiors are
            reshaping the future of home design:
          </>
        ),
      },

      {
        type: "h3",
        content: "1. Space Efficiency",
      },
      {
        type: "p",
        content:
          "Modern homes, especially in urban environments, often face the challenge of limited space. Modular interiors solve this by optimizing every square inch. Whether cleverly designed storage units, foldable furniture, or multifunctional pieces, modular designs ensure no space goes to waste.",
      },
      {
        type: "h3",
        content: "2. Personalization Like Never Before",
      },
      {
        type: "p",
        content:
          "No two homes are the same, and neither are the people who live in them. Modular interiors offer unparalleled customization, allowing homeowners to tailor their spaces to reflect their unique preferences and lifestyles. Whether it’s choosing materials, colors, layouts, or configurations, modular designs adapt to you—not the other way around.",
      },
      {
        type: "h3",
        content: "3. Built to Last",
      },
      {
        type: "p",
        content:
          "Quality and longevity are at the core of modular interiors. With precision engineering and premium materials, modular furniture is designed to withstand the test of time. It’s not just about beauty; it’s about creating designs that endure.",
      },
      {
        type: "h2",
        content: "Why Choose Rostre Spaces?",
      },
      {
        type: "p",
        content:
          "At Rostre Spaces, we believe in combining innovation with integrity to deliver genuinely transformative interiors. Here’s what sets us apart:",
      },
      {
        type: "ul",
        content: [
          {
            bold: "Transparent Processes:",
            text: " From the initial consultation to the final installation, we keep you informed and involved at every step.",
          },
          {
            bold: "Tailored Solutions:",
            text: " Our designs are created specifically for your space and needs, ensuring your home feels yours.",
          },
          {
            bold: "Commitment to Quality:",
            text: " We design every piece with care, attention to detail, and a focus on excellence.",
          },
        ],
      },
      {
        type: "image",
        src: "../images/blogbanner-new-2.png", // Replace this with the actual image path
        title: "Explore Modular Interior Designs by Rostre Spaces",
      },

      {
        type: "p",
        content:
          "Whether you’re looking to revamp a single room or transform your entire home, our team at Rostre Spaces is here to make it happen. We create spaces that are functional and bring joy and inspiration to your everyday life.",
      },
      {
        type: "h2",
        content: "Discover the Future of Interiors with Rostre Spaces",
      },
      {
        type: "p",
        content:
          "Modular interiors aren’t just a trend—they’re the future. Let’s design a home that reflects who you are and how you live. Connect with us today to start your modular journey!",
      },
    ],
  },

  {
    id: 2,
    title: "Modular Interiors: Transforming Homes with Style and Functionality",
    subtitle: "The Future of Personalized Interiors",
    image: "./images/blogthumb1.png",
    bannerImage: "../images/blogbanner-new-3.png",
    description: [
      {
        type: "p",
        content:
          "Home interiors are transforming. The era of one-size-fits-all designs is over. Modular interiors are leading the charge, offering customization, space optimization, and long-lasting quality that cater to the demands of contemporary lifestyles. At Rostre Spaces, we don’t just embrace this trend; we’re shaping its future.",
      },
      {
        type: "p",
        content: "Here’s why modular interiors are the way forward:",
      },
      {
        type: "ul",
        content: [
          {
            bold: "Optimized Space Utilization:",
            text: " Every corner of your home is maximized to its potential.",
          },
          {
            bold: "Personalized Touch:",
            text: " Designs that adapt seamlessly to your unique preferences and needs.",
          },
          {
            bold: "Durability and Quality:",
            text: " Built with precision, using premium materials that stand the test of time.",
          },
        ],
      },
      {
        type: "p",
        content:
          "At Rostre Spaces, we merge innovation with transparency to deliver bespoke designs tailored to you. With a commitment to excellence and attention to detail, we aim to create spaces that inspire and delight.",
      },
      {
        type: "image",
        src: "../images/blogbanner-new-4.png",
        title: "Your Dream Space, Made Simple.",
      },
      {
        type: "p",
        content:
          "We'd like to help you reimagine your home. Explore the future of interiors with Rostre Spaces.",
      },
    ],
  },
];

const BlogDetailPage = () => {
  const { id } = useParams();
  const blog = blogs.find((blog) => blog.id === parseInt(id));

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <>
      <div className="Banner">
        <img src={banner} alt="" />
        <h1>Blog</h1>
      </div>

      <div className="wrapper blog-inner">
        {/* Banner Image */}
        <div className="banner">
          <h1>{blog.title}</h1>
          <img
            src={blog.bannerImage}
            alt={`${blog.title} Banner`}
            className="banner-image"
          />
        </div>

        {/* Blog Content */}
        <div className="content">
          {/* <h3>{blog.subtitle}</h3> */}

          <div>
            {blog.description.map((item, index) => {
              if (item.type === "p") {
                return <p key={index}>{item.content}</p>;
              } else if (item.type === "h2") {
                return <h2 key={index}>{item.content}</h2>;
              } else if (item.type === "h3") {
                return <h3 key={index}>{item.content}</h3>;
              } else if (item.type === "ul") {
                return (
                  <ul key={index}>
                    {item.content.map((listItem, idx) => (
                      <li key={idx}>
                        <strong>{listItem.bold}</strong>
                        {listItem.text}
                      </li>
                    ))}
                  </ul>
                );
              } else if (item.type === "image") {
                return (
                  <div
                    key={index}
                    style={{ textAlign: "center", margin: "20px 0" }}
                  >
                    <img
                      src={item.src}
                      alt={item.title}
                      style={{ maxWidth: "100%" }}
                    />
                    <p style={{ fontStyle: "italic" }}>{item.title}</p>
                  </div>
                );
              }
              return null;
            })}
          </div>

          <Link to="/" className="backtoblog">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M20.2376 13.0719L7.98975 13.2992L13.3363 18.4509L12.6903 19.213L6.0708 12.8347L12.4491 6.21525L13.1229 6.95288L7.9712 12.2994L20.2191 12.0721L20.2376 13.0719Z"
                fill="#B09955"
              />
            </svg>
            Back to Blog Page
          </Link>
        </div>
      </div>
    </>
  );
};

export default BlogDetailPage;
