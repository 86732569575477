import React, { useState, useEffect } from "react";
import OnloadPopup from "./OnloadPopup";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import banner from "./images/about-us-banner.png";
import Section1 from "./images/Section-1-img.png";
import aboutimg1 from "./images/about-img-12.png";

import aboutthumb from "./images/about-thumb-4.png";
import aboutthumb1 from "./images/about-thumb-2.png";
import aboutthumb2 from "./images/about-thumb-5.png";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  disable: isMobile,
});
const photogallery = [
  require("./images/pg-h-1.png"),
  require("./images/pg-h-2.png"),
  require("./images/pg-h-3.png"),
  require("./images/pg-h-4.png"),
  require("./images/pg-h-5.png"),
  require("./images/pg-h-6.png"),
  require("./images/pg-h-7.png"),
  require("./images/pg-h-8.png"),
];

const instagallery = [
  require("./images/insta-h-1.png"),
  require("./images/insta-h-2.png"),
  require("./images/insta-h-3.png"),
  require("./images/insta-h-4.png"),
  require("./images/insta-h-5.png"),
  require("./images/insta-h-6.png"),
  require("./images/insta-h-7.png"),
  require("./images/insta-h-8.png"),
  require("./images/insta-h-7.png"),
  require("./images/insta-h-8.png"),
];

function Aboutus() {
  {
    /*Popup scripts start */
  }
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 30 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Change to 30000 for 30 seconds

    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {/* {showPopup && <OnloadPopup closePopup={closePopup} />} */}
      <div>
        <div className="Banner">
          <img src={banner} alt="" />
          <h1>Legacy that continues to unfold and innovate</h1>
        </div>
        <div className="about-section-1-bg  ">
          <div className="wrapper">
            <div className="home-section-1-wrap">
              <div className="home-section-1-1">
                <img src={Section1} alt="Section1" data-aos="zoom-in-up" />
              </div>
              <div className="home-section-1-2" data-aos="fade-up-left">
                <h2>Our Commitment to Innovation</h2>
                <p>
                  Rostre Spaces stands out as a leader in the surfaces industry,
                  specializing in granite and quartz for over 30 years. Our
                  legacy of manufacturing excellence is now available for your
                  homes and offices, offering exceptional modular furniture
                  solutions, including kitchens and wardrobes.
                </p>

                <p>
                  At Rostre Spaces, we merge traditional craftsmanship with
                  cutting-edge technology. Our state-of-the-art, fully automated
                  facilities enable us to achieve unparalleled precision and
                  quality in every piece we create. This commitment to
                  innovation ensures that our products not only meet but exceed
                  industry standards.
                </p>
                <Link to="/why-choose-rostre" className="button-3" on>
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="about-bg-2">
          <div className="wrapper">
            <div className="about-bg-2-wrap">
              <div className="about-bg-2-1" data-aos="fade-up-right">
                <h3>Our Mission</h3>
                <p>
                  At Rostre Spaces, our mission is to deliver exceptional
                  cabinetry and countertop solutions that consistently exceed
                  expectations. We are dedicated to ensuring that our customers
                  receive exactly what is promised, when it is promised. As a
                  company rooted in honesty and transparency, we strive to stand
                  out by delivering quality products and services without
                  compromise. Your satisfaction is our priority—always.
                </p>
                <div className="line-2"></div>
                <h3>Our Vision</h3>
                <p>
                  At Rostre Spaces, we aspire to become a global leader in
                  cabinetry and countertop solutions. Our goal is to set new
                  benchmarks for quality, sustainability, and design in the
                  industry. We believe that every living space has the potential
                  to be transformed into an elegant haven, reflecting the unique
                  style and needs of its inhabitants. Through innovative
                  craftsmanship and a commitment to excellence, we aim to create
                  environments that inspire and elevate everyday living.
                </p>
              </div>
              <div className="about-bg-2-2" data-aos="fade-up-left">
                <img src={aboutimg1} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="about-bg-3">
          <div className="wrapper" data-aos="zoom-in-up">
            <h2 className="line-1 text-center"> Manufacturing Facilities</h2>
            <p>
              <b>Rostre Spaces</b> has the advantage of three state of the art
              Manufacturing Facilities which makes us a comprehensive one stop
              solution for all your modular furniture needs.
            </p>
            <p>
              <b>Rostre Cabinet</b> is our modular furniture manufacturing unit,
              that boasts of high end automated German machinery, barcoded
              processes and precision equipment.
            </p>
            <p>
              <b>Divine Surfaces</b> is a comprehensive engineered quartz
              production facility, equipped with advanced 4-axis bridge cutters,
              5-axis CNC, and 5-axis waterjet machinery, to fulfill the demands
              of commercial and residential projects worldwide. Our premium
              slabs are crafted with precision, ensuring consistent quality and
              rapid turnaround times.
            </p>
            <p>
              <b>Granite Mart</b> has been into the natural stone industry for
              three decades, complete with a multi-wire saw, Italian polishing
              lines and multiple edge and surface finish machines to give
              diversity to our products.
            </p>
            <p>
              Looking ahead, the company aims to further enhance production
              capabilities and reduce turnaround times. Plans are underway to
              expand the fabrication section with additional machinery and
              increase the shed area for storage and quality inspection. Our
              dedicated team is here to meet all your requirements while
              maintaining our highest standards of quality.
            </p>
            <p></p>

            {/* <div className="about-thumb-list">
              <img src={aboutthumb} alt="aboutthumb" data-aos="zoom-in-up" />
              <img src={aboutthumb} alt="aboutthumb" data-aos="zoom-in-up" />
              <img src={aboutthumb} alt="aboutthumb" data-aos="zoom-in-up" />
            </div> */}

            <div className="category-images" id="ManufacturingFacilities">
              <Link to="/divinespaces" onClick={scrollToTop}>
                <img src={aboutthumb1} alt="aboutthumb" data-aos="zoom-in-up" />
                <div className="catname">Divine Surfaces</div>
              </Link>
              <Link to="/gml" onClick={scrollToTop}>
                <img src={aboutthumb2} alt="aboutthumb" data-aos="zoom-in-up" />
                <div className="catname">Granite Mart Ltd</div>
              </Link>
              <Link to="/rostrecabinets" onClick={scrollToTop}>
                <img src={aboutthumb} alt="aboutthumb" data-aos="zoom-in-up" />
                <div className="catname">Rostre Cabinet</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
