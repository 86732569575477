// CategoriesPage.js
import React from "react";
import { Link } from "react-router-dom";
import banner from "./images/QuartzProductsBannerNew.jpg";

// categories.js
const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const categories = {
  ProductCategories: {
    name: "",
    description:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour",
    items: [
      {
        id: 1,
        name: "Islands",
        thumbnail: "../images/Islands.png",
      },
      {
        id: 2,
        name: "Backsplash/Sidesplash",
        thumbnail: "../images/BacksplashSidesplash.png",
      },
      {
        id: 3,
        name: "Vanity tops",
        thumbnail: "../images/Vanitytops.png",
      },
      {
        id: 4,
        name: "Countertops",
        thumbnail: "../images/Countertops.png",
      },
      {
        id: 5,
        name: "Window Sills",
        thumbnail: "../images/WindowSills.png",
      },
      {
        id: 6,
        name: "Table tops",
        thumbnail: "../images/Tabletops.png",
      },
      {
        id: 7,
        name: "Wall Claddings",
        thumbnail: "../images/WallCladdings.png",
      },
    ],
  },
};

const QuartzProducts = () => (
  <div>
    <div className="Banner">
      <img src={banner} alt="" />
      <h1> Products</h1>
    </div>
    <div className="product-sec-1-wrap">
      <div className="wrapper">
        {/* <p>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English. Many desktop publishing packages and web
          page editors now use Lorem Ipsum as their default model text, and a
          search for 'lorem ipsum' will uncover many web sites still in their
          infancy. Various versions have evolved over the years, sometimes by
          accident, sometimes on purpose (injected humour and the like).
        </p> */}
        <p>
          We provide an extensive range of quartz products from our company
          Divine Surfaces. Designed to enhance the beauty and functionality of
          your home, all our quartz products are crafted using advanced
          technology to ensure precision and quality.{" "}
        </p>
        <p>
          All products are available With a focus on durability, stain
          resistance, and easy maintenance, our quartz solutions are designed to
          withstand the demands of everyday life while maintaining their
          stunning appearance.{" "}
        </p>
      </div>
    </div>

    <div className="product-list-wrap-2">
      {Object.keys(categories).map((category) => (
        <div key={category} className="product-list-bg">
          <div className="wrapper">
            {/* <h2>{category}</h2>  */}
            {/* <p>{categories[category].description}</p>{" "} */}
            {/* Display category description */}
            <div className="category-images">
              {categories[category].items.map((item) => (
                <Link
                  key={item.id}
                  //to={`/QuartzProducts/${category}/${item.id}`}
                >
                  <img
                    src={item.thumbnail}
                    alt={item.name}
                    onClick={scrollToTop}
                  />
                  <div className="catname catname-noimg" onClick={scrollToTop}>
                    {item.name}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default QuartzProducts;
