import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import OnloadPopup from "./OnloadPopup";
import { isMobile } from "react-device-detect";
import banner from "./images/about-us-banner.png";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  disable: isMobile,
});

const instagallery = [
  require("./images/fact-1.png"),
  require("./images/fact-2.png"),
  require("./images/fact-3.png"),
  require("./images/fact-4.png"),
  require("./images/fact-5.png"),
  require("./images/fact-6.png"),
];

const videoGallery = [
  {
    src: require("./new/ROSTRECatalogue.mp4"),
    poster: require("./images/video4-poster.png"), // Optional poster image
    title: "Video 1",
  },
  {
    src: require("./new/C0001.mp4"),
    poster: require("./images/video1-poster.png"), // Optional poster image
    title: "Video 2",
  },
  {
    src: require("./new/C0067.mp4"),
    poster: require("./images/video3-poster.png"), // Optional poster image
    title: "Video 3",
  },
  {
    src: require("./new/C0066.mp4"),
    poster: require("./images/video2-poster.png"), // Optional poster image
    title: "Video 4",
  },
  {
    src: require("./new/C0001.mp4"),
    poster: require("./images/video1-poster.png"), // Optional poster image
    title: "Video 5",
  },
  // Add more videos as needed
];

function RostreCabinets() {
  const [showPopup, setShowPopup] = useState(false);
  const videoRefs = useRef([]); // Reference array to store video elements

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Show popup after 5 seconds
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false,
      disable: () => window.innerWidth < 768,
    });
  }, []);

  const handlePlay = (index) => {
    // Pause all other videos
    videoRefs.current.forEach((video, i) => {
      if (i !== index && video) {
        video.pause();
      }
    });
  };
  return (
    <div>
      {/* {showPopup && <OnloadPopup closePopup={closePopup} />} */}
      <div>
        <div className="Banner">
          <img src={banner} alt="Banner" />
          <h1>Rostre Cabinet</h1>
        </div>
        <div className="product-sec-1-wrap" data-aos="zoom-in-up">
          <div className="wrapper">
            {/* <h2>Rostre Cabinets</h2> */}
            <p>
              Sy No. 161/162, Venkatapur Village, Brahmanpally Panchayat,
              Toopran Mandal, Medak District, Telangana- 502334
            </p>
          </div>
          <div className="wrapper">
            <div className="videosec">
              {videoGallery.map((video, i) => (
                <div key={video.id} className="video-container">
                  <video
                    ref={(el) => (videoRefs.current[i] = el)}
                    controls
                    poster={video.poster}
                    width="100%"
                    onPlay={() => handlePlay(i)}
                  >
                    <source src={video.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ))}
            </div>
          </div>

          <div className="wrapper">
            <div className="gallery">
              {instagallery.map((src, i) => (
                <img src={src} alt="" />
              ))}
            </div>
          </div>
          <div className="backbtn">
            <Link to="/Aboutus#ManufacturingFacilities" className="button-3">
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RostreCabinets;
