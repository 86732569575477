// metaData.js
const metaData = {
  "/": {
    title:
      "Modular Furniture for Homes & Offices - Rostre Spaces | best modular kitchen designs in hyderabad",
    description:
      "Welcome to Rostre Spaces, your destination for modular furniture and granite solutions.Experience high-quality, customizable modular furniture designed for homes and offices.",
    keywords:
      "modular furniture, residential modular furniture,corporate modular furniture,modular kitchens,modular wardrobes,granite surfaces,quartz surfaces,engineered stone surfaces,furniture manufacturing,interior design solutions,best modular kitchen designs in Hyderabad",
    canonical: "https://rostrespaces.com",
  },
  "/ModularFurniture": {
    title: "Affordable modular furniture for homes - Rostre Spaces",
    description:
      "Explore our wide range of modular furniture solutions for your home and office.",
    keywords: "modular furniture, home office furniture, modern designs",
    canonical: "https://rostrespaces.com/ModularFurniture",
  },
  "/Granite": {
    title: "Granite Products - Rostre Spaces",
    description:
      "Browse through our premium granite collection for your interiors.",
    keywords: "granite countertops, granite products, stone solutions",
    canonical: "https://rostrespaces.com/ModularFurniture",
  },

  "/Aboutus": {
    title: "Customizable office workstations in Telangana, Rostre Spaces",
    description:
      "We specialize in crafting modular furniture that combines functionality, elegance, and innovation to redefine interiors for homes and offices.",
    keywords: "granite countertops, granite products, stone solutions",
    canonical: "https://rostrespaces.com/Aboutus",
  },
  "/Projects": {
    title: "High-quality granite and quartz furniture surfaces, Rostre Spaces",
    description:
      "Browse through our premium granite collection for your interiors.",
    keywords:
      "Modular furniture installation services,Custom furniture design services,Furniture maintenance and repair,Interior design consultation,Space planning services,",
    canonical: "https://rostrespaces.com/Projects",
  },
  "/Export": {
    title: "Rostre Spaces Interior Design",
    description:
      "Browse through our premium granite collection for your interiors.",
    keywords:
      "Modular furniture industry trends,Innovations in modular kitchen designs,Sustainable furniture manufacturing,Latest office furniture designs, Modular furniture materials",
    canonical: "https://rostrespaces.com/Export",
  },
  "/why-choose-rostre": {
    title:
      "Space-saving modular furniture for small apartments,  Rostre Spaces",
    description:
      "Custom designs tailored to your needs.Premium materials for durability.Affordable pricing.Professional installation services.Exceptional customer support.",
    keywords:
      "Rostre modular furniture,Rostre kitchen solutions,Rostre office furniture, Rostre interior design",
    canonical: "https://rostrespaces.com/why-choose-rostre",
  },
  "/Contactus": {
    title: "Rostre Spaces",
    description:
      "Browse through our premium granite collection for your interiors.",
    keywords:
      "Modular furniture in Hyderabad,Kitchen solutions in Telangana,Office furniture in Hyderabad,Interior design services in Telangana,Furniture manufacturers in Hyderabad",
    canonical: "https://rostrespaces.com/Contactus",
  },
  "/Blog": {
    title: "Rostre Spaces",
    description:
      "Browse through our premium granite collection for your interiors.",
    keywords: "granite countertops, granite products, stone solutions",
    canonical: "https://rostrespaces.com/Blog",
  },
  "/OurProcess": {
    title: "Rostre Spaces",
    description:
      "Browse through our premium granite collection for your interiors.",
    keywords: "granite countertops, granite products, stone solutions",
    canonical: "https://rostrespaces.com/OurProcess",
  },
  "/": {
    title: "Rostre Spaces",
    description:
      "Browse through our premium granite collection for your interiors.",
    keywords: "granite countertops, granite products, stone solutions",
    canonical: "https://rostrespaces.com/",
  },
  "/RostreCabinets": {
    title: "Rostre Spaces",
    description:
      "Browse through our premium granite collection for your interiors.",
    keywords:
      "Modular kitchen cabinets,Modular wardrobe designs,Office workstations,Modular storage units,Granite and quartz countertops,",
    canonical: "https://rostrespaces.com/RostreCabinets",
  },
  "/GML": {
    title: "Rostre Spaces",
    description:
      "Browse through our premium granite collection for your interiors.",
    keywords:
      "Modular kitchen cabinets,Modular wardrobe designs,Office workstations",
    canonical: "https://rostrespaces.com/GML",
  },
  "/DivineSpaces": {
    title: "Rostre Spaces",
    description:
      "Browse through our premium granite collection for your interiors.",
    keywords:
      "Modular kitchen cabinets,Modular wardrobe designs,Office workstations,Modular storage units,",
    canonical: "https://rostrespaces.com/DivineSpaces",
  },
  "/RostreCabinetsGlobal": {
    title: "Rostre Spaces",
    description:
      "Browse through our premium granite collection for your interiors.",
    keywords:
      "Modular wardrobe designs,Office workstations,Modular storage units",
    canonical: "https://rostrespaces.com/RostreCabinetsGlobal",
  },
  "/GraniteGlobal": {
    title: "Rostre Spaces",
    description:
      "Browse through our premium granite collection for your interiors.",
    keywords: "Modular storage units,Granite and quartz countertops,",
    canonical: "https://rostrespaces.com/GraniteGlobal",
  },

  // Add other routes here...
};

export default metaData;
