const QuartzcolourRangeListDataPage = {
    argento: {
      name : "Argento",
      images: [
        {
          path : "februusbeige4020",
          imageName: "4020 Februus Beige",
          src: "/images/ColourRange/Argento/4020.png",
          alt: "4020 Februus Beige",
      
        },
        {
          path : "monetabeige4021",
          imageName: "4021 Moneta Beige",
          src: "/images/ColourRange/Argento/4021.png",
          alt: "4021 Moneta Beige",
        },
        {
          path : "biamcopepe5005",
          imageName: "5005 Bianco Pepe",
          src: "/images/ColourRange/Argento/5005.png",
          alt: "5005 Bianco Pepe",
      
        },
        {
          path : "aradiabeige5008",
          imageName: "5008 Aradia Beige",
          src: "/images/ColourRange/Argento/5008.png",
          alt: "5008 Aradia Beige",
        },
        {
          path : "grigiocristallo6015",
          imageName: "6015 Grigio Cristallo",
          src: "/images/ColourRange/Argento/6015.png",
          alt: "6015 Grigio Cristallo",
      
        },
        {
          path : "grigiocristallo6016",
          imageName: "6016 Grigio Cristallo",
          src: "/images/ColourRange/Argento/6016.png",
          alt: "6016 Grigio Cristallo",
        },
        {
          path : "aradiagrigio6025",
          imageName: "6025 Aradia Grigio",
          src: "/images/ColourRange/Argento/6025.png",
          alt: "6025 Aradia Grigio",
        },
        {
          path: "grigioarmani6029",
          imageName: "6029 Grigio Armani",
          src: "/images/ColourRange/Argento/6029.png",
          alt: "6029 Grigio Armani",
        },
      ],

    },
    //.2
    bronzo: {
      name : "Bronzo",
      images: [
        {
           path : "ciottolobeige5004",
          imageName: "5004 Ciottolo Beige",
          src : "/images/ColourRange/Bronzo/bronzo-5004.jpg",
          // src: "images/ColourRange/Bronzo/bronzo-5004.png",
          alt: "5004 Ciottolo Beige",
        },
        {
          path: "cemento6027",
          imageName : "6027 Cemento",
          src: "/images/ColourRange/Bronzo/bronzo-6027.jpg",
          alt: "6027 Cemento",
          // description: "This is Item B description",
        },
        
      ],
    },
    //.3
    cobalto: {
      name : "Cobalto",
      images: [
        {
          path: "nerissimo1050",
          imageName: "1050 Nerissimo",
          src: "/images/ColourRange/Cobalto/1050.png",
          alt: "1050 Nerissimo",
        },
        {
          path: "aradianero1051",
          imageName: "1051 Aradia Nero",
          src: "/images/ColourRange/Cobalto/1051.png",
          alt: "1051 Aradia Nero",
        },
        {
          path: "blackvein1052",
          imageName: "1052 Black Vein",
          src: "/images/ColourRange/Cobalto/1052.png",
          alt: "1052 Black Vein",
        },
        {
          path: "summerstorm3031",
          imageName: "3031 Summer Storm",
          src: "/images/ColourRange/Cobalto/3031.png",
          alt: "3031 Summer Storm",
        },
        {
          path: "saharadream3032",
          imageName: "3032 Sahara Dream",
          src: "/images/ColourRange/Cobalto/3032.png",
          alt: "3032 Sahara Dream",
        },
        {
          path: "cloudywhite3033",
          imageName: "3033 Cloudy White",
          src: "/images/ColourRange/Cobalto/3033.png",
          alt: "3033 Cloudy White",
        },
        {
          path: "cremavenice3034",
          imageName: "3034 Crema Venice",
          src: "/images/ColourRange/Cobalto/3034.png",
          alt: "3034 Crema Venice",
        },
        {
          path: "raindrops3035",
          imageName: "3035 Raindrops",
          src: "/images/ColourRange/Cobalto/3035.png",
          alt: "3035 Raindrops",
        },
        {
          path: "romagrigio6012",
          imageName: "6012 Roma Grigio",
          src: "/images/ColourRange/Cobalto/6012.png",
          alt: "6012 Roma Grigio",
        },
        {
          path: "biancopuro9018",
          imageName: "9018 Bianco Puro",
          src: "/images/ColourRange/Cobalto/9018.png",
          alt: "9018 Bianco Puro",
        },
      ],
      
    },
    //.4
    oro: {
      name : "Oro",
      images: [
        {
          path: "biancoacero9020",
          imageName: "9020 Bianco Acero",
          src: "/images/ColourRange/Oro/9020.png",
          // src: "images/ColourRange/Bronzo/bronzo-5004.png",
          alt: "9020 Bianco Acero",
        },
        {
          path: "aradiabianca4014",
          imageName: "4014 Aradia Bianca",
          src: "/images/ColourRange/Oro/4014.png",
          alt: "4014 Aradia Bianca",
          // description: "This is Item B description",
        },
        {
          path: "cappuccino4019",
          imageName: "4019 Cappuccino",
          src: "/images/ColourRange/Oro/4019.png",
          alt: "4019 Cappuccino",
        },
        {
          path: "aradiachocolati4022",
          imageName: "4022 Aradia Chocolati",
          src: "/images/ColourRange/Oro/4022.png",
          alt: "4022 Aradia Chocolati",
        },
        {
          path: "ceresbeige5009",
          imageName: "5009 Ceres Beige",
          src: "/images/ColourRange/Oro/5009.png",
          alt: "5009 Ceres Beige",
        },
        {
          path: "grigiopuro6023",
          imageName: "6023 Grigio Puro",
          src: "/images/ColourRange/Oro/6023.png",
          alt: "6023 Grigio Puro",
        },
        {
          path: "grigiojapetus6024",
          imageName: "6024 Grigio Japetus",
          src: "/images/ColourRange/Oro/6024.png",
          alt: "6024 Grigio Japetus",
        },
        {
          path: "aradialivid6026",
          imageName: "6026 Aradia Livid",
          src: "/images/ColourRange/Oro/6026.png",
          alt: "6026 Aradia Livid",
        },
        {
          path: "grigiochiaro6028",
          imageName: "6028 Grigio Chiaro",
          src: "/images/ColourRange/Oro/6028.png",
          alt: "6028 Grigio Chiaro",
        },
        {
          path: "concerto6030",
          imageName: "6030 Concerto",
          src: "/images/ColourRange/Oro/6030.png",
          alt: "6030 Concerto",
        },
        {
          path: "biancofortuna9019",
          imageName: "9019 Bianco Fortuna",
          src: "/images/ColourRange/Oro/9019.png",
          alt: "9019 Bianco Fortuna",
        },
        {
          path: "biancocristallo9022",
          imageName: "9022 Bianco Cristallo",
          src: "/images/ColourRange/Oro/9022.png",
          alt: "9022 Bianco Cristallo",
        },
        {
          path: "miscellaarruzzo9024",
          imageName: "9024 Miscella Arruzzo",
          src: "/images/ColourRange/Oro/9024.png",
          alt: "9024 Miscella Arruzzo",
        },
      ]
    },
    //.5
    palladio: {
      name : "Palladio",
      images: [
        {
          path: "calacattasottile8021",
          imageName: "8021 Calacatta Sottile",
          src: "/images/ColourRange/Palladio/8021.jpg",
          // src: "images/ColourRange/Bronzo/bronzo-5004.png",
          alt: "8021 Calacatta Sottile",
        },
        {
          path: "calacattaesotico8022",
          imageName: "8022 Calacatta Esotico",
          src: "/images/ColourRange/Palladio/8022.jpg",
          alt: "8022 Calacatta Esotico",
          // description: "This is Item B description",
        },
        {
          path: "calacattaashnova8023",
          imageName: "8023 Calacatta Ash Nova",
          src: "/images/ColourRange/Palladio/8023.jpg",
          alt: "8023 Calacatta Ash Nova",
          // description: "This is Item B description",
        },
        {
          path: "calacattaashen8024",
          imageName: "8024 Calacatta Ashen",
          src: "/images/ColourRange/Palladio/8024.jpg",
          alt: "8024 Calacatta Ashen",
          // description: "This is Item B description",
        },
        {
          path: "calacattadusky8025",
          imageName: "8025 Calacatta Dusky",
          src: "/images/ColourRange/Palladio/8025.jpg",
          alt: "8025 Calacatta Dusky",
          // description: "This is Item B description",
        },
        {
          path: "calacattasmoky8026",
          imageName: "8026 Calacatta Smoky",
          src: "/images/ColourRange/Palladio/8026.jpg",
          alt: "8026 Calacatta Smoky",
          // description: "This is Item B description",
        },
      ]
      
    },
      //.6
    platino: {
      name : "Platino",
      images: [
        {
           path : "carraraoroe8019B",
          imageName: "8019B Carrara Oroe",
          src : "/images/ColourRange/Platino/8020-Carrara-Linea.jpg",
          // src: "images/ColourRange/Bronzo/bronzo-5004.png",
          alt: "8019B Carrara Oro",
        },
        {
          path: "carraralinea8020",
          imageName : "8020 Carrara Linea",
          src: "/images/ColourRange/Platino/8019B-Carrara-Oro.jpg",
          alt: "8020 Carrara Linea",
          // description: "This is Item B description",
        },
        {
          path: "patragrizzle6031",
          imageName : "6031 Piatra Grizzle",
          src: "/images/ColourRange/Platino/6031-Piatra-Grizzle.jpg",
          alt: "6031 Piatra Grizzle",
          // description: "This is Item B description",
        },
      ]
    },
  };
  
  export default QuartzcolourRangeListDataPage;
  