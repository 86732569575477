import React from "react";
import { useParams } from "react-router-dom";
import QuartzcolourRangeListDataPage from "./QuartzColourRangeListData";
import banner from "./images/Quartz-banner.png";
import { Link } from "react-router-dom";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const QuartzItemPage = () => {
  const { categoryname, itemname } = useParams(); // Get category name (e.g., Argento) from URL params
  console.log("Category Name:", categoryname); // Log the category name to ensure it's correct

  // Get category data dynamically based on categoryName (Argento, Bronzo, etc.)
  const category = QuartzcolourRangeListDataPage[(categoryname, itemname)];
  console.log(category);

  // Handle case where the category is not found
  if (!category) {
    return <p>Category not found</p>;
  }

  // Display the category data (description, origin, etc.)
  return (
    <div>
      <div className="Banner">
        <img src={banner} alt="Quartz Banner" />
        <h1>Quartz</h1>
      </div>
      <div className="product-sec-1-wrap">
        <div className="wrapper">
          <h1 className="line-3">{category.name}</h1>
        </div>
      </div>

      <div className="product-list-wrap-2">
        <div className="product-list-bg">
          <div className="wrapper">
            <div className="category-images">
              {category.images &&
                category.images.length > 0 &&
                category.images.map((image, index) => (
                  <Link
                    key={index}
                    to={`/quartz/colourrange/${itemname}/${image.path}/details`} // Construct URL dynamically
                  >
                    <img
                      src={image.src}
                      alt={image.alt}
                      onClick={scrollToTop}
                    />

                    <div className="catname" onClick={scrollToTop}>
                      {image.imageName}
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuartzItemPage;
