import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { Route, Routes, useLocation } from "react-router-dom"; // Import useLocation
import metaData from "./Components/metaData";

import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home";

import ModularFurniture from "./Components/ModularFurniture";
import OurProcess from "./Components/OurProcess";
import Aboutus from "./Components/Aboutus";
import Export from "./Components/Export";
import Projects from "./Components/Projects";
// import whychoosetrostre from "./Components/Whychoosetrostre";
import WhyChooseRostre from "./Components/WhyChooseRostre";
import Contactus from "./Components/Contactus";
import EdgeProfiles from "./Components/EdgeProfiles";
import Quartz from "./Components/Quartz";
import Granite from "./Components/Granite";
import ModularFurnitureInsidePage from "./Components/ModularFurnitureInsidePage";
import GraniteInsidePage from "./Components/GraniteInsidePage";
import QuartzProductsInsidePage from "./Components/QuartzProductsInsidePage";
import EdgeProfilesInsidePage from "./Components/EdgeProfilesInsidePage";

import QuartzProducts from "./Components/QuartzProducts";
import BlogDetailPage from "./Components/BlogDetailPage";
import Blog from "./Components/Blog";
import RostreCabinets from "./Components/RostreCabinets";
import GraniteGlobal from "./Components/GraniteGlobal";
import RostreCabinetsGlobal from "./Components/RostreCabinetsGlobal";
import DivineSpaces from "./Components/DivineSpaces";
import DivineSpacesGlobal from "./Components/DivineSpacesGlobal";
import GML from "./Components/GML";

//quartz-pages
import QuartzInsidePage from "./Components/QuartzInsidePage";
import QuartzItemPage from "./Components/QuartzSubDataPage";
import ColourRangeDetailsPage from "./Components/ColourRangeDetailsPage ";

// categories.js
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  disable: isMobile,
});

function MetaTags() {
  const location = useLocation();
  const currentPath = location.pathname;

  // Fetch metadata based on the current path from metaData object
  const currentMeta = metaData[currentPath] || {
    title:
      "Modular Furniture for Homes & Offices - Rostre Spaces | best modular kitchen designs in hyderabad",
    description:
      "Welcome to Rostre Spaces, your destination for modular furniture and granite solutions.Experience high-quality, customizable modular furniture designed for homes and offices.",
    keywords:
      "best modular kitchen designs in Hyderabad, modular furniture, residential modular furniture,corporate modular furniture,modular kitchens,modular wardrobes,granite surfaces,quartz surfaces,engineered stone surfaces,furniture manufacturing,interior design solutions,",
  };
  useEffect(() => {
    // Dynamically setting document title and meta tags
    document.title = currentMeta.title;
    const metaDescription = document.querySelector('meta[name="description"]');
    const metaKeywords = document.querySelector('meta[name="keywords"]');
    const canonical = document.querySelector('meta[name="canonical"]');

    if (metaDescription) {
      metaDescription.setAttribute("content", currentMeta.description);
    }
    if (metaKeywords) {
      metaKeywords.setAttribute("content", currentMeta.keywords);
    }
    if (canonical) {
      canonical.setAttribute("content", currentMeta.canonical);
    }
  }, [currentPath, currentMeta]);

  return (
    <Helmet>
      <meta name="description" content={currentMeta.description} />
      <meta name="keywords" content={currentMeta.keywords} />
      <link rel="canonical" name="canonical" href={currentMeta.canonical} />
    </Helmet>
  );
}

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);

  return (
    <div className="App">
      <Header />
      {/* MetaTags outside of Routes */}
      <MetaTags />
      <Routes>
        <Route element={<Home />} path="/" />
        <Route path="/modularfurniture" element={<ModularFurniture />} />
        <Route
          path="/modularfurniture/:category/:name"
          element={<ModularFurnitureInsidePage />}
        />
        <Route path="/granite" element={<Granite />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/export" element={<Export />} />
        <Route path="/why-choose-rostre" element={<WhyChooseRostre />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/granite/:category/:id" element={<GraniteInsidePage />} />
        <Route path="/ourprocess" element={<OurProcess />} />
        <Route path="/edgeprofiles" element={<EdgeProfiles />} />
        <Route path="/quartz" element={<Quartz />} />
        <Route path="/quartz/products" element={<QuartzProducts />} />
        <Route path="/blog/:id" element={<BlogDetailPage />} />
        <Route
          path="/quartzproducts/:category/:id"
          element={<QuartzProductsInsidePage />}
        />
        <Route
          path="/edgeprofiles/:category/:id"
          element={<EdgeProfilesInsidePage />}
        />
        <Route path="/quartz/:categoryname" element={<QuartzInsidePage />} />
        <Route
          path="/quartz/:categoryname/:itemname"
          element={<QuartzItemPage />}
        />
        <Route
          path="/quartz/colourrange/:categoryname/:itemname/details"
          element={<ColourRangeDetailsPage />}
        />
        <Route path="/rostrecabinets" element={<RostreCabinets />} />
        <Route path="/gml" element={<GML />} />
        <Route path="/divinespaces" element={<DivineSpaces />} />
        <Route path="/divinespacesglobal" element={<DivineSpacesGlobal />} />
        <Route
          path="/rostrecabinetsglobal"
          element={<RostreCabinetsGlobal />}
        />
        <Route path="/graniteglobal" element={<GraniteGlobal />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
