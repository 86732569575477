import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QuartzcolourRangeDetailsData from "./QuartzColourRangeDetailsPage";
import banner from "./images/Quartz-banner.png";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const ColourRangeDetailsPage = () => {
  const [SelectedSubImage, SetSelectedSubImage] = useState("");
  const { categoryname, itemname } = useParams(); // Extract category and item names
  console.log("Category Name:", categoryname);
  console.log("Item Name:", itemname);

  // Fetch the item details dynamically using itemName
  const category = QuartzcolourRangeDetailsData[itemname];
  console.log(category);

  //updating main image
  const onClickSubImage = (image) => {
    SetSelectedSubImage(image);
    // console.log(image.src)
    // alert(image.name)
    // alert(SelectedSubImage)
  };

  if (!category) {
    return <p>Category not found</p>;
  }

  return (
    <div>
      <div className="Banner">
        <img src={banner} alt="Quartz Banner" />
        <h1>Quartz</h1>
      </div>
      <div className="Product-content-wrap">
        <div className="wrapper">
          <div className="Product-content-1">
            <h1 className="line-3">{category?.name}</h1>
          </div>
          <h1>Product Details</h1>
          <div className="Products-inner-wrap">
            <div className="Products-inner-1">
              <p>Color: {category?.productsData?.[0]?.color}</p>
              <p>
                Country of Origin:{" "}
                {category?.productsData?.[0]?.countryOfOrigin}
              </p>
              <p>Colour Name: {category?.productsData?.[0]?.colourName}</p>
              <p>Colour Code: {category?.productsData?.[0]?.colourCode}</p>
              <p>Stone Form: {category?.productsData?.[0]?.stoneForm}</p>
              <p>Stone Finish: {category?.productsData?.[0]?.stoneFinish}</p>
              <p>Size: {category?.productsData?.[0]?.size}</p>
              <p>Thickness: {category?.productsData?.[0]?.thickness}</p>
              <br />
              <h2>Basic Parameters</h2>
              {/* Conditionally rendering based on the type of data in basicParams */}
              {category?.basicParams?.map((param, index) => (
                <div key={index}>
                  <p>Absorption by weight: {param.absorptionByWeight}</p>
                  {/* Check if appDensityKG exists or if it is a dry/wet condition */}
                  {param.appDensityKG ? (
                    <p>App. Density KG/M3 (Cube): {param.appDensityKG}</p>
                  ) : (
                    <p>Density: {param.density}</p>
                  )}
                  <p>Abrasion resistance: {param.abrasionResistance}</p>

                  {/* Handle modulus of rupture, flexural strength, and compress strength with dry/wet conditions */}
                  {param.modulusOfRupture &&
                  typeof param.modulusOfRupture === "object" ? (
                    param.modulusOfRupture.dryCondition &&
                    param.modulusOfRupture.wetCondition ? (
                      <>
                        <p>
                          Modulus of rupture (Dry Condition):{" "}
                          {param.modulusOfRupture.dryCondition}
                        </p>
                        <p>
                          Modulus of rupture (Wet Condition):{" "}
                          {param.modulusOfRupture.wetCondition}
                        </p>
                      </>
                    ) : param.modulusOfRupture.range ? (
                      <p>
                        Modulus of rupture (Range):{" "}
                        {param.modulusOfRupture.range}
                      </p>
                    ) : null
                  ) : (
                    <p>Modulus of rupture: {param.modulusOfRupture}</p>
                  )}

                  {param.flexuralStrength &&
                  typeof param.flexuralStrength === "object" ? (
                    param.flexuralStrength.dryCondition &&
                    param.flexuralStrength.wetCondition ? (
                      <>
                        <p>
                          Flexural strength (Dry Condition):{" "}
                          {param.flexuralStrength.dryCondition}
                        </p>
                        <p>
                          Flexural strength (Wet Condition):{" "}
                          {param.flexuralStrength.wetCondition}
                        </p>
                      </>
                    ) : param.flexuralStrength.range ? (
                      <p>
                        Flexural strength (Range):{" "}
                        {param.flexuralStrength.range}
                      </p>
                    ) : null
                  ) : (
                    <p>Flexural strength: {param.flexuralStrength}</p>
                  )}

                  {param.compressStrength &&
                  typeof param.compressStrength === "object" ? (
                    param.compressStrength.dryCondition &&
                    param.compressStrength.wetCondition ? (
                      <>
                        <p>
                          Compress strength (Dry Condition):{" "}
                          {param.compressStrength.dryCondition}
                        </p>
                        <p>
                          Compress strength (Wet Condition):{" "}
                          {param.compressStrength.wetCondition}
                        </p>
                      </>
                    ) : param.compressStrength.range ? (
                      <p>
                        Compress strength (Range):{" "}
                        {param.compressStrength.range}
                      </p>
                    ) : null
                  ) : (
                    <p>Compress strength: {param.compressStrength}</p>
                  )}

                  <p>Mohs' hardness: {param.mohsHardness}</p>
                  <p>Specular gloss: {param.specularGloss}</p>
                </div>
              ))}
            </div>
            <div className="Products-inner-2">
              <div className="ColourRange-images-1">
                {category.images &&
                  category.images.map((image, index) => (
                    // <img
                    //   key={index}
                    //   src={image.src}
                    //   alt={image.alt}
                    // />
                    <React.Fragment key={index}>
                      {SelectedSubImage ? (
                        // If SelectedSubImage exists, display it
                        <img
                          src={SelectedSubImage.src}
                          alt={SelectedSubImage.alt}
                        />
                      ) : (
                        // Otherwise, display the default images
                        <img src={image.src} alt={image.alt} />
                      )}
                    </React.Fragment>
                  ))}
              </div>
              <div className="ColourRange-images-2">
                {category?.Subimages?.length ? (
                  category.Subimages.map((Subimages, index) => (
                    <div
                      className="cim-2-thumb"
                      key={index}
                      onClick={() => onClickSubImage(Subimages)}
                    >
                      <img
                        src={Subimages.src}
                        alt={Subimages.alt}
                        // style={{ margin: "10px", width: "150px", height: "150px" }}
                      />
                      <p>{Subimages.name}</p>
                    </div>
                  ))
                ) : (
                  <p>No sub-images available</p>
                )}
              </div>
            </div>
          </div>
          {/* sub-Images-list */}
          <div className="Products-sub-images"></div>
        </div>
      </div>
    </div>
  );
};

export default ColourRangeDetailsPage;
