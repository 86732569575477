//colour Range page Quartz Data Page 2

const QuartzDataPage = {
  colourrange: {
    id: "ColourRange",
    description:
      "dgfdffLorem Ipsum is simply dummy text of the printing and typesetting industry.",
    banner: "/images/Granite-banner.png",
    images: [
      {
        src: "../images/ColourRange/Argento.png",
        alt: "Argento",
        imageName: "argento",
        title: "Argento",
      },
      {
        src: `../images/ColourRange/Bronzo.png`,
        alt: "Bronzo",
        imageName: "bronzo",
        title: "Bronzo",
      },
      {
        src: `../images/ColourRange/Cobalto.png`,
        alt: "Cobalto",
        imageName: "cobalto",
        title: "Cobalto",
      },
      {
        src: `../images/ColourRange/Oro.png`,
        alt: "Oro",
        imageName: "oro",
        title: "Oro",
      },
      {
        src: `../images/ColourRange/Palladio.png`,
        alt: "Palladio",
        imageName: "palladio",
        title: "Palladio",
      },
      {
        src: `../images/ColourRange/Platino.png`,
        alt: "Platino",
        imageName: "platino",
        title: "Platino",
      },
    ],
  },
};

export default QuartzDataPage;
