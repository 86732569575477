import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import larrow from "./images/left-arrow.png";
import rarrow from "./images/right-arrow.png";

const images = [
  // {
  //   src: require("./images/Granite/Granite-img-1.png"),
  //   imageName: "Black",
  //   alt: "Black",
  // },
  // {
  //   src: require("./images/Granite/Granite-img-2.png"),
  //   alt: "Blues",
  //   imageName: "Blues",
  // },
  // {
  //   src: require("./images/Granite/Granite-img-3.png"),
  //   alt: "Product Image 3",
  //   imageName: "Browns and Reds",
  // },
  // {
  //   src: require("./images/Granite/Granite-img-4.png"),
  //   alt: "Product Image 4",
  //   imageName: "Greens",
  // },
  // {
  //   src: require("./images/Granite/Granite-img-5.png"),
  //   alt: "Product Image 5",
  //   imageName: "Marbles",
  // },
  // {
  //   src: require("./images/Granite/Granite-img-6.png"),
  //   alt: "Product Image 6",
  //   imageName: "Red and Whites",
  // },
  // {
  //   src: require("./images/Granite/Granite-img-6.png"),
  //   alt: "Product Image 7",
  //   imageName: "Yellow",
  // },
  // {
  //   src: require("./images/Granite/Granite-img-7.png"),
  //   alt: "Product Image 8",
  //   imageName: "Yellows and Brown",
  // },
  // {
  //   src: require("./images/Granite/Granite-img-8.png"),
  //   alt: "Product Image 9",
  //   imageName: "Yellows and Creams",
  // },

  {
    src: require("./images/Granite/Black-Galaxy.png"),
    imageName: "Black Galaxy",
    alt: "Black Galaxy",
  },
  {
    src: require("./images/Granite/Absolute-Black.png"),
    alt: "Absolute Black",
    imageName: "Absolute Black",
  },
  {
    src: require("./images/Granite/River-Black.png"),
    alt: "River Black",
    imageName: "River Black",
  },
  // {
  //   src: require("./images/Granite/Spicy-Black.png"),
  //   alt: "Spicy Black Scaled",
  //   imageName: "Spicy Black Scaled",
  // },

  {
    src: require("./images/Granite/Sapphire-Blue.png"),
    alt: "Sapphire Blue Granite",
    imageName: "Sapphire Blue Granite",
  },
  {
    src: require("./images/Granite/Coffee-Brown.png"),
    alt: "Coffee Brown",
    imageName: "Coffee Brown",
  },
  {
    src: require("./images/Granite/Surf-Green.png"),
    alt: "Surf Green Granite Slab",
    imageName: "Surf Green Granite Slab",
  },
  {
    src: require("./images/Granite/River-White.png"),
    alt: "River White Granite 400x400",
    imageName: "River White Granite",
  },

  {
    src: require("./images/Granite/Moon-White.png"),
    alt: "Moon White",
    imageName: "Moon White",
  },
  {
    src: require("./images/Granite/Black-Pearl.png"),
    alt: "Black Pearl Granite 400x400",
    imageName: "Black Pearl Granite",
  },
  {
    src: require("./images/Granite/Silver-Pearl.png"),
    alt: "Silver Pearl",
    imageName: "Silver Pearl",
  },
  {
    src: require("./images/Granite/Tan-Brown.png"),
    alt: "Tan Brown",
    imageName: "Tan Brown",
  },
  {
    src: require("./images/Granite/Viscon-White.png"),
    alt: "Viscon White",
    imageName: "Viscon White",
  },
];

function GraniteColourSlider() {
  const sliderRef = useRef(null); // Reference for the slider

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 1024, // For screens smaller than 1024px
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768, // For screens smaller than 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480, // For screens smaller than 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [activeSlide, setActiveSlide] = useState(0);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
      <div className="slider-home">
        <Slider ref={sliderRef} {...settings}>
          {images.map((image, i) => (
            <div
              key={i}
              className={`slide ${i === activeSlide ? "zoom" : ""}`} // Apply zoom class to active slide
            >
              <img src={image.src} alt={image.name} />
              <p className="image-name">{image.imageName}</p>
            </div>
          ))}
        </Slider>
        <div className="slider-buttons">
          <img src={larrow} onClick={handlePrev} className="prev-button" />
          <img src={rarrow} onClick={handleNext} className="next-button" />
        </div>
      </div>
    </>
  );
}

export default GraniteColourSlider;
