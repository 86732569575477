import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import AOS from "aos";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import banner from "./images/Blog-banner2.png";

import blogthumb1 from "./images/blogthumb1.png";
import blogthumb2 from "./images/blogthumb2.png";
import blogthumb3 from "./images/blogthumb3.png";

import OnloadPopup from "./OnloadPopup";

AOS.init({
  disable: isMobile,
});

const photogallery = [
  require("./images/pg-h-8.png"),
  require("./images/pg-h-5.png"),
  require("./images/pg-h-7.png"),
  require("./images/pg-h-4.png"),
  require("./images/pg-h-2.png"),
  require("./images/pg-h-1.png"),
  require("./images/pg-h-6.png"),
  require("./images/pg-h-3.png"),
];

const instagallery = [
  require("./images/insta-h-1.png"),
  require("./images/insta-h-2.png"),
  require("./images/insta-h-3.png"),
  require("./images/insta-h-4.png"),
  require("./images/insta-h-5.png"),
];

const blogs = [
  {
    id: 1,
    title: "The Future of Home Design: ",
    subtitle: "Why Modular Interiors Lead the Way",
    image: blogthumb1,
    description:
      "Discover the elegance and functionality of modern cabinets for every space.",
  },
  {
    id: 2,
    title: "Modular Interiors",
    subtitle: "Transforming Homes with Style and Functionality",
    image: blogthumb2,
    description:
      "Explore how minimalist kitchen designs can transform your home.",
  },
];
function Blog() {
  {
    /*Popup scripts start */
  }
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 30 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Change to 30000 for 30 seconds

    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {/* {showPopup && <OnloadPopup closePopup={closePopup} />} */}
      <div className="Banner">
        <img src={banner} alt="" />
        <h1>Blog</h1>
      </div>
      <div>
        <div className="home-section-3-bg">
          <div className="wrapper">
            {/* <div className="blog-sec">
              
            
              <div className="blog-item">
                <Link to="/">
                  <img src={blogthumb1} alt="" />
                  <div className="blog-content">
                    <h4>Modern Cabinets </h4>
                    <h5>Lorem Ipsum</h5>
                  </div>
                </Link>
              </div>
            </div> */}
            <div className="blog-sec">
              {blogs.map((blog) => (
                <div key={blog.id} className="blog-item">
                  <Link to={`/blog/${blog.id}`} onClick={scrollToTop}>
                    <img src={blog.image} alt={blog.title} />
                    <div className="blog-content">
                      <h4>{blog.title}</h4>
                      <h5>{blog.subtitle}</h5>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
