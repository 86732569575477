// CategoriesPage.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import AOS from "aos";
import "aos/dist/aos.css";
import SupplierCollaborations from "./SupplierCollaborations";
import Warranty from "./Warranty";

import banner from "./images/Modular-Furniture-banner.png";
import op1 from "./images/Consultation-Design.png";

import op2 from "./images/Custom-Craftsmanship.png";
import op3 from "./images/Seamless-Installation.png";
import op4 from "./images/Final-Walkthrough-1.png";

import rb1 from "./images/Tailored-Designs.png";
import rb2 from "./images/Premium-Quality-Materials.png";
import rb3 from "./images/Expert-Craftsmanship.png";
import rb4 from "./images/End-to-End-Service.png";
import rb5 from "./images/Innovative-Storage-Solutions.png";
import rb6 from "./images/warranty.png";
// categories.js

import OnloadPopup from "./OnloadPopup";
AOS.init({
  disable: isMobile,
});
// categories.js

const categories = {
  productcategories: {
    name: "Explore our Products",
    description: " ",
    items: [
      {
        id: 1,
        name: "kitchens",
        heading: "Kitchens",
        thumbnail: "./images/Kitchen-1-new.png",
      },
      {
        id: 2,
        name: "wardrobes",
        heading: "Wardrobes",
        thumbnail: "./images/Wardrobes-1.png",
      },
      {
        id: 3,
        name: "bathroomvanities",
        heading: "Bathroom Vanities",
        thumbnail: "./images/Bathroom-vanities-1.png",
      },
      {
        id: 4,
        name: "consoles",
        heading: "Consoles",
        thumbnail: "./images/TV-consoles-1.png",
      },
      {
        id: 5,
        name: "storageunits",
        heading: "Storage Units",
        thumbnail: "./images/Storage-units-1.png",
      },
      {
        id: 6,
        name: "officefurniture",
        heading: "Office Furniture",
        thumbnail: "./images/office-furniture-new.png",
      },
    ],
  },


  MaterialOptions: {
    name: "Shutter Finishes",
    description:
      "Choose from any of the following shutter finishes completed with perfectly matched edge banding",
    items: [
      {
        id: 1,
        name: "laminates",
        heading: "Laminates",
        thumbnail: "/images/Laminates.png",
      },
      {
        id: 2,
        name: "paintandpu",
        heading: "Paint / PU",
        thumbnail: "/images/PU.png",
      },
      {
        id: 3,
        name: "acrylic",
        heading: "Acrylic",
        thumbnail: "/images/Glass.png",
      },
      {
        id: 4,
        name: "glass",
        heading: "Glass",
        thumbnail: "/images/Acrylic.png",
      },
      {
        id: 5,
        name: "thermofoilandmembrane",
        heading: "Thermofoil / Membrane",
        thumbnail: "/images/Thermofoil.png",
      },

      // Add more items as needed
    ],
  },


  CoreMaterials: {
    name: "Materials and Finishes",
    description: [
      "The main carcass of any modular furniture is made in plywood for durability and we offer a wide range in that including boiling water proof plywood and moisture resistant plywood. However we do offer other engineered woods like MDF, HDHMR, particle board and chipboard as options. \n",
      "\n The internal hardware used is from leading Indian and international brands and covered by warranties and guarantees.",
    ],

    items: [
      {
        id: 1,
        name: "MDF",
        thumbnail: "/images/MDF.png",
        category: "Engineered wood",
      },
      {
        id: 2,
        name: "HDF-HMR",
        thumbnail: "/images/HDF-HMR.png",
        category: "Engineered wood",
      },
      {
        id: 3,
        name: "Particle Board",
        thumbnail: "/images/Particles-Board.png",
        category: "Engineered wood",
      },
      {
        id: 4,
        name: "Chip Board",
        thumbnail: "/images/Chip-Board.png",
        category: "Engineered wood",
      },

      {
        id: 5,
        name: "MR",
        thumbnail: "/images/Particles-Board.png",
        category: "Plywood",
      },
      {
        id: 6,
        name: "Honed",
        thumbnail: "/images/Chip-Board.png",
        category: "Plywood",
      },
      {
        id: 7,
        name: "BWP",
        thumbnail: "/images/Particles-Board.png",
        category: "Plywood",
      },
    ],
  },

  OfficeFurniture: {
    name: "Office Furniture",
    description: [
      "We can make modular furniture for your working spaces such as",
    ],

    items: [
      {
        id: 1,
        name: "Reception Tables",
        thumbnail: "/images/ReceptionTables.png",
        category: "Office Furniture",
      },
      {
        id: 2,
        name: "Office Desks",
        thumbnail: "/images/Officedesks.png",
        category: "Office Furniture",
      },
      {
        id: 3,
        name: "Storage and Filing Units",
        thumbnail: "/images/offStorage.png",
        category: "Office Furniture",
      },
      {
        id: 4,
        name: "Cabinets",
        thumbnail: "/images/offCabinets.png",
        category: "Office Furniture",
      },
    ],
  },
};


const scrollToTop = () => {
  window.scrollTo(0, 0);
};

function ModularFurniture() {
  const location = useLocation();
  {
    /*Popup scripts start */
  }
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 30 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 30000); // Change to 30000 for 30 seconds

    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div>
      {/* {showPopup && <OnloadPopup closePopup={closePopup} />} */}
      <div className="Banner">
        <img src={banner} alt="" />
        <h1>Unmatched Quality and Exquisite Craftsmanship</h1>
      </div>
      <div className="product-sec-1-wrap" data-aos="zoom-in-up">
        <div className="wrapper">
          {/* <h2>Uncompromising Quality, Distinct Craftsmanship</h2> */}
          <p>
            At Rostre, we appreciate that your home is a canvas for
            self-expression. We blend stone craftsmanship, design, and
            manufacturing innovation to produce beautiful and highly functional
            furniture. Our dedication to excellence transcends aesthetics; it
            encompasses our commitment to complete honesty and transparency and
            delivering what we promise. Utilizing cutting-edge manufacturing
            technology, automated barcoded processes, we ensure that every piece
            is crafted to the highest quality standards. Installation is
            seamlessly executed, and our team will be there after that, in case
            you need support. We have a wide range of materials to choose from
            for the carcass and the finishes, soft closing hardwares from top
            international and Indian brands, giving you a complete mix of
            options to curate your space with.
          </p>
          <p>
            <i>
              Rostre’s modular furniture sets a new standard for a chic living.
            </i>
          </p>
        </div>
      </div>

      <div className="warenty-sec">
        <div className="wrapper">
          <Warranty />
        </div>
      </div>

      <div className="product-list-wrap">
        <div className="product-list-bg">
          <div className="wrapper">
            <h2 className="line-1 text-center">
              {categories.productcategories.name}
            </h2>
            <p>{categories.productcategories.description}</p>

            <div className="category-images">
              <div
                className="remaining-images remaining-images-2"
                onClick={scrollToTop}
              >
                {categories.productcategories.items.map((item) => (
                  <Link
                    key={item.name}
                    to={`/modularfurniture/productcategories/${item.name}`}
                    data-aos="zoom-in-up"
                  >
                    <img src={item.thumbnail} alt={item.heading} />
                    <div className="catname">{item.heading}</div>
                  </Link>
                ))}
              </div>
            </div>

            {/* <div className="category-images">
              <div className="first-image" onClick={scrollToTop}>
                <Link
                  to={`/ModularFurniture/productcategories/${categories.productcategories.items[0].id}`}
                >
                  <img
                    data-aos="fade-up-right"
                    src={categories.productcategories.items[0].thumbnail}
                    alt={categories.productcategories.items[0].name}
                  />
                  <div className="catname">
                    {categories.productcategories.items[0].name}
                  </div>
                </Link>
              </div>

              <div className="remaining-images">
                {categories.productcategories.items.slice(1).map((item) => (
                  <Link
                    data-aos="zoom-in-up"
                    key={item.id}
                    to={`/ModularFurniture/productcategories/${item.id}`}
                  >
                    <img src={item.thumbnail} alt={item.name} />
                    <div className="catname">{item.name}</div>
                  </Link>
                ))}
              </div>
            </div> */}
          </div>
        </div>

        {/* Section for Core Materials */}
        {/* <div className="product-list-bg">
          <div className="wrapper">
            <h3>{categories.CoreMaterials.name}222</h3>
            <p>{categories.CoreMaterials.description}</p>
            <div className="category-images">
              {categories.CoreMaterials.items.map((item) => (
                <Link
                  key={item.id}
                  to={`/ModularFurniture/CoreMaterials/${item.id}`}
                  data-aos="zoom-in-up"
                >
                  <img src={item.thumbnail} alt={item.name} />
                  <div className="catname">{item.name}</div>
                </Link>
              ))}
            </div>
          </div>
        </div> */}

        {/* Start */}
        <div className="product-list-bg">
          <div className="wrapper">
            <h3 className="line-3 text-center">
              {categories.CoreMaterials.name}
            </h3>
            {/* <p>{categories.CoreMaterials.description}</p> */}
            <p>
              Explore a wide selection of high-quality materials for your
              modular furniture needs. Our offerings—from high-density HDHMR and
              moisture-resistant MDF to premium boiling water-proof plywood—are
              designed for lasting durability and timeless elegance.
            </p>
            <h3 className="line-3 text-center">Core material</h3>
            <p>
              The main carcass of any modular furniture is made in plywood for
              durability and we offer a wide range in that including boiling
              water proof plywood and moisture resistant plywood. However we do
              offer other engineered woods like MDF, HDHMR, particle board and
              chipboard as options.
            </p>
            <p>
              The internal hardware used is from leading Indian and
              international brands and covered by warranties and guarantees.
            </p>
            <h3 className="line-3 text-center">Aesthetics</h3>
            <p>
              The aesthetics of the furniture include the{" "}
              <b>shutter finishes, edge banding, handles and the countertops</b>{" "}
              and backsplash used.
            </p>
            <p>
              We have a large number of tie-ups with leading Indian and
              international brands for you to choose from for your{" "}
              <b>handles</b>. We have Gola Handles, Knobs, Profile Handles and
              Regular Handles.
            </p>
            <p>
              The <b>countertops, vanities and backsplashes</b> can be made in{" "}
              <b>granite or quartz</b> directly from our factory and installed
              along with the kitchen. A wide range of colours and prints are
              available.
            </p>

            <p>
              We also provide perfectly matching <b>edge banding</b> on all our
              shutters all pressed in German machines to give the precision and
              quality in the finished product.
            </p>

            {/* Engineered wood section */}
            {/* <div className="category-section">
              <h4>Engineered Wood</h4>
              <div className="category-images">
                {categories.CoreMaterials.items
                  .filter((item) => item.category === "Engineered wood")
                  .map((item) => (
                    //<Link  key={item.id} to={`/ModularFurniture/CoreMaterials/${item.id}`} data-aos="zoom-in-up  >
                    <Link
                      key={item.id}
                      //to={`/ModularFurniture/CoreMaterials/${item.id}`}
                      data-aos="zoom-in-up"
                    >
                      <img src={item.thumbnail} alt={item.name} />
                      <div className="catname">{item.name}</div>
                    </Link>
                  ))}
              </div>
            </div> */}

            {/* Plywood section */}
            <div className="category-section">
              {/* <h4>Plywood</h4> */}
              {/* <div className="category-images">
                {categories.CoreMaterials.items
                  .filter((item) => item.category === "Plywood")
                  .map((item) => (
                    //<Link  key={item.id} to={`/ModularFurniture/CoreMaterials/${item.id}`} data-aos="zoom-in-up"  >
                    <Link
                      key={item.id}
                      // to={`/ModularFurniture/CoreMaterials/${item.id}`}
                      data-aos="zoom-in-up"
                    >
                      <img src={item.thumbnail} alt={item.name} />
                      <div className="catname">{item.name}</div>
                    </Link>
                  ))}
              </div> */}
            </div>
          </div>
        </div>

        {/* End */}

        {/* Section for Material Options */}
        <div className="product-list-bg">
          <div className="wrapper" id="materialsandfinishes">
            <h3 className="line-3 text-center">
              {categories.MaterialOptions.name}
            </h3>
            <p>{categories.MaterialOptions.description}</p>
            <div className="category-images">
              {/* First image */}
              <div className="first-image" onClick={scrollToTop}>
                <Link
                  to={`/modularfurniture/materialoptions/${categories.MaterialOptions.items[0].name}`}
                >
                  <img
                    data-aos="fade-up-right"
                    src={categories.MaterialOptions.items[0].thumbnail}
                    alt={categories.MaterialOptions.items[0].heading}
                  />
                  <div className="catname">
                    {categories.MaterialOptions.items[0].heading}
                  </div>
                </Link>
              </div>
              {/* Remaining images */}
              <div className="remaining-images">
                {categories.MaterialOptions.items.slice(1).map((item) => (
                  <Link
                    data-aos="zoom-in-up"
                    key={item.name}
                    to={`/modularfurniture/materialoptions/${item.name}`}
                    onClick={scrollToTop}
                  >
                    <img src={item.thumbnail} alt={item.heading} />
                    <div className="catname">{item.heading}</div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Start */}

        {/* <div className="product-list-bg" id="OfficeFurniture">
          <div className="wrapper">
            <h3>{categories.OfficeFurniture.name}</h3>
            <p>{categories.OfficeFurniture.description}</p>
            <div className="category-images">
              {categories.OfficeFurniture.items.map((item) => (
                <Link
                  key={item.id}
                to={`/ModularFurniture/CoreMaterials/${item.id}`}
                  data-aos="zoom-in-up"
                >
                  <img src={item.thumbnail} alt={item.name} />
                  <div className="catname">{item.name}</div>
                </Link>
              ))}
            </div>
          </div>
        </div> */}

        {/* End */}
      </div>

      <div className="Our-Process-bg">
        <div className="wrapper">
          <h2>Our Process</h2>
          <div className="Our-Process-wrap">
            <div className="Our-Process-1">
              <div className="Our-Process-1-1">
                <img src={op1} alt="op1" />
              </div>
              <div className="Our-Process-1-2">
                <h4>Consultation & Design</h4>
                <p>
                  Every project begins by understanding your unique vision. Our
                  design team provides personalized consultations to uncover
                  your needs and preferences, offering tailored recommendations
                  that harmonize aesthetics with functionality. From material
                  choices to spatial configurations, we ensure meticulous
                  planning for your dream space. It can be only a kitchen or a
                  wardrobe or a bathroom or the entire home or office – we have
                  it covered.
                </p>
              </div>
            </div>
            <div className="Our-Process-1">
              <div className="Our-Process-1-1">
                <img src={op2} alt="op1" />
              </div>
              <div className="Our-Process-1-2">
                <h4>Custom Craftsmanship</h4>
                <p>
                  At Rostre, craftsmanship is a cherished tradition. Every piece
                  is custom-built to your specifications, merging artistry with
                  advanced manufacturing techniques. We meticulously work with
                  premium materials to deliver cabinetry that is visually
                  stunning yet robust. Our attention to detail guarantees that
                  each groove, edge, and finish enhances the overall elegance of
                  your home. Completely automated barcoded processes on German
                  machinery ensures precision and quality.
                </p>
              </div>
            </div>
            <div className="Our-Process-1">
              <div className="Our-Process-1-1">
                <img src={op3} alt="op1" />
              </div>
              <div className="Our-Process-1-2">
                <h4>Seamless Installation</h4>
                <p>
                  Our installation process is crafted to be smooth and
                  efficient. Our expert team ensures that each piece is
                  installed with the utmost precision and care, seamlessly
                  fitting into your space. We manage every aspect of the
                  process, from initial planning to final adjustments, ensuring
                  a flawless finish with minimal disruption.
                </p>
              </div>
            </div>
            <div className="Our-Process-1">
              <div className="Our-Process-1-1">
                <img src={op4} alt="op1" />
              </div>
              <div className="Our-Process-1-2">
                <h4>Final Walkthrough</h4>
                <p>
                  Your satisfaction is paramount. After installation, we conduct
                  a thorough final walkthrough to ensure every detail meets our
                  high standards. We address any concerns and make necessary
                  adjustments, leaving you with a beautifully transformed space
                  that reflects your unique style and vision. After sales
                  service is always guaranteed.
                </p>
              </div>
            </div>
          </div>
          <div className="center-sec-1">
            <Link to="/contactus" onClick={scrollToTop} className="button-2">
              Learn More
            </Link>
          </div>
        </div>
      </div>
      <div className="Rostre-Benefits-bg">
        <div className="wrapper">
          <h2>Key Features</h2>
          <div className="Rostre-Benefits-wrap">
            <div className="Rostre-Benefits-1">
              <div className="Rostre-Benefits-1-box" data-aos="zoom-in-up">
                <div className="Rostre-Benefits-1-1">
                  <img src={rb1} alt="rb1" />
                </div>
                <div className="Rostre-Benefits-1-2">
                  <h4>Customizable Options</h4>
                  <p>
                    Personalize your modular furniture with a wide range of
                    colors, finishes, and layouts that suit your style and space
                    requirements.
                  </p>
                </div>
              </div>
            </div>

            <div className="Rostre-Benefits-1">
              <div className="Rostre-Benefits-1-box" data-aos="zoom-in-up">
                <div className="Rostre-Benefits-1-1">
                  <img src={rb2} alt="rb1" />
                </div>
                <div className="Rostre-Benefits-1-2">
                  <h4>High-Quality Materials</h4>
                  <p>
                    Our kitchens and countertops are crafted from premium
                    materials, ensuring durability and timeless beauty.
                  </p>
                </div>
              </div>
            </div>

            <div className="Rostre-Benefits-1">
              <div className="Rostre-Benefits-1-box" data-aos="zoom-in-up">
                <div className="Rostre-Benefits-1-1">
                  <img src={rb3} alt="rb1" />
                </div>
                <div className="Rostre-Benefits-1-2">
                  <h4>Wide Range</h4>
                  <p>
                    The carcass can be made in plywood, chip board, particle
                    board and many more options, whereas the shutters can be
                    finished in glass, acrylic, paint, laminate, veneer and
                    anything else you can imagine.
                  </p>
                </div>
              </div>
            </div>
            <div className="Rostre-Benefits-1">
              <div className="Rostre-Benefits-1-box" data-aos="zoom-in-up">
                <div className="Rostre-Benefits-1-1">
                  <img src={rb4} alt="rb1" />
                </div>
                <div className="Rostre-Benefits-1-2">
                  <h4>Functional Design</h4>
                  <p>
                    Enjoy features such as soft-closing drawers, customizable
                    mounts, and high-quality hardware sourced from leading
                    suppliers, guaranteeing a seamless blend of style and
                    practicality.
                  </p>
                </div>
              </div>
            </div>
            <div className="Rostre-Benefits-1">
              <div className="Rostre-Benefits-1-box" data-aos="zoom-in-up">
                <div className="Rostre-Benefits-1-1">
                  <img src={rb5} alt="rb1" />
                </div>
                <div className="Rostre-Benefits-1-2">
                  <h4>Transformative Spaces</h4>
                  <p>
                    Our modular solutions are designed to enhance your
                    environment, turning your kitchen into a chic and functional
                    hub for cooking and gathering.
                  </p>
                </div>
              </div>
            </div>

            <div className="Rostre-Benefits-1">
              <div className="Rostre-Benefits-1-box" data-aos="zoom-in-up">
                <div className="Rostre-Benefits-1-1">
                  <img src={rb6} alt="rb1" />
                </div>
                <div className="Rostre-Benefits-1-2">
                  <h4>Warranty</h4>
                  <p>
                    Our furniture comes with a 10 year flat warranty against all
                    manufacturing defects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Supplier-Collaborations">
        <div className="wrapper">
          <h3 className="line-3 text-center">Supplier Collaborations</h3>
          <SupplierCollaborations />
        </div>
      </div>
    </div>
  );
}

export default ModularFurniture;
